<template>
    <div class="container table-currency">
      <h3>Актуальные курсы валют (ЦБ РФ):</h3>
      <div class="tbl-header">
        <table class="table">
        <thead>
          <tr class="h_tr">
            <th>Код</th>
            <th>Валюта</th>
            <th class="pulse-circle">Курс ЦБ <div class="circle pulse blue"></div></th>
          </tr>
        </thead>
        </table>
    </div>
    
    <div class="tbl-content">
    <table>
        <tbody>
          <tr v-for="(details, currency) in rates" :key="currency">
            <td>{{ currency }}</td>
            <td>{{ details.name }}</td>
            <td>{{ details.rate }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from "vue";
  
  export default {
    name: "CurrencyRatesTable",
    setup() {
      const rates = ref({});
      
      const fetchRates = async () => {
        try {
          const response = await fetch(
            "https://www.cbr-xml-daily.ru/daily_json.js"
          );
          const data = await response.json();
          const currencies = {};
          
          Object.keys(data.Valute).forEach((key) => {
            currencies[key] = {
              name: data.Valute[key].Name,
              rate: data.Valute[key].Value.toFixed(2),
            };
          });
          
          rates.value = currencies;
        } catch (error) {
          console.error("Ошибка при загрузке курсов ЦБ РФ:", error);
        }
      };
  
      onMounted(() => {
        fetchRates();
        setInterval(fetchRates, 30000);
      });
  
      return { rates };
    },
  };
  </script>
  
  <style scoped>
  table{
  width:100%;
  table-layout: fixed;
  margin-bottom: 0rem;
}
.tbl-header{
  background-color: rgba(255,255,255,0.3);
 }
.tbl-content{
  height:300px;
  overflow-x:auto;
  margin-top: 0px;
  border: 1px solid rgba(255,255,255,0.3);
}
th {
    padding: 12px;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    color: #000;
    text-transform: uppercase;
}
.table thead th {
    border-bottom: 0px solid #dee2e6 !important;
}
td{
  padding: 15px;
  text-align: left;
  vertical-align:middle;
  font-weight: 300;
  font-size: 13px;
  color: #fff;
  border-bottom: solid 1px rgba(255,255,255,0.1);
}
thead{
    background: #ffffff;
}
.table-currency{
    margin-top: 40px;
    margin-bottom: 40px;
}
::-webkit-scrollbar {
    width: 7px;
} 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
} 
::-webkit-scrollbar-thumb {
    color: inset 0 0 6px rgb(0, 0, 0); 
}
.blue{
    background: #0bb38f;
}
  </style>
  