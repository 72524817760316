<template>
    <div :class="['notification', type, { show: isVisible }]">
      <p class="message_copy">{{ message }}</p>
    </div>
</template>
  
<script>
  import { ref, onMounted } from 'vue';
  
  export default {
    props: {
      message: String,
      type: {
        type: String,
        default: 'success',
      },
    },
    setup(props) {
      const isVisible = ref(false);
  
      onMounted(() => {
        // Плавное появление
        setTimeout(() => {
          isVisible.value = true;
        }, 200);
  
        setTimeout(() => {
          isVisible.value = false;
        }, 3000);
      });
  
      return {
        isVisible,
      };
    },
  };
</script>
<style scoped>
.notification {
  position: fixed;
  right: 20px;
  top: 24px;
  padding: 20px;
  color: white;
  font-weight: 600;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 0;
  transform: translateX(117px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.notification.success {
  background-color: #18ae77; /* зеленый для успеха */
}

.notification.warning {
  background-color: #e74c3c; /* красный для предупреждения */
}

.notification.show {
    opacity: 1;
    transform: translateY(0);
}

.message_copy {
    margin-bottom: 0;
    margin-top: -6px;
}
  </style>