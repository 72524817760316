<template>
    <footer class="footer-section animate__animated animate__fadeIn">
        <ul class="footer-menu">
            <h6>©2024 Автор: <a class="personality" target="_blank" href="https://it-klondike.eu.org/it-personality/"> IŦ-Personality</a></h6>
            <a class="and_prog" href="https://t.me/ait_bro01z" target="_blank">Telegram</a>
            <a href="https://www.donationalerts.com/r/maxshowpro" target="_blank">Поддержать рублем</a>
        </ul>
    </footer>
</template>
  
  <script>
  export default {
    name: 'HelloWorld',
    props: {
      msg: String
    }
  }
  </script>
  