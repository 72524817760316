<template>
  <div>
    <div class="group">      
      <input
        type="text"
        required
        id="oamount"
        v-model="amount"
        @input="filterDigits"
      />
      <span class="highlight"></span>
      <span class="bar"></span>
      <label for="oamount">Сумма для конвертации:</label>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="input-group">
          <div class="custom-select">
            <div class="selected-item" @click="toggleDropdown('from')">
              {{ fromCurrencyName || 'Выберите валюту…' }}
            </div>
            <ul v-if="dropdown.open === 'from'" class="dropdown-menu">
              <li
                v-for="currency in orderedCurrencies"
                :key="currency"
                @click="selectCurrency('from', currency)"
              >
                {{ currency }} - {{ getCurrencyName(currency) }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="input-group">
          <div class="custom-select">
            <div class="selected-item" @click="toggleDropdown('to')">
              {{ toCurrencyName || 'Выберите валюту…' }}
            </div>
            <ul v-if="dropdown.open === 'to'" class="dropdown-menu">
              <li
                v-for="currency in orderedCurrencies"
                :key="currency"
                @click="selectCurrency('to', currency)"
              >
                {{ currency }} - {{ getCurrencyName(currency) }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="button-group mt-3">
      <button class="btn btn-primary" @click="convertCurrency">Конвертировать</button>
      <button class="btn btn-primary" @click="resetFields">Сброс</button>
    </div>

    <div v-if="finalConvertedAmount !== null" class="mt-3 convents">
      <h3>
        Конвертированная сумма составляет:
        <strong class="conv">{{ finalConvertedAmount }}</strong>
        {{ finalToCurrency }}
      </h3>
      <button class="btn copy" @click="copyToClipboard">Копировать</button>
    </div>

    <Notification
      v-if="showNotification"
      :message="notificationMessage"
      :type="notificationType"
    />
  </div>
</template>


<script>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import Notification from "./Notification.vue";

export default {
  components: {
    Notification,
  },
  setup() {
    const amount = ref("");
    const fromCurrency = ref("");
    const toCurrency = ref("");
    const rates = ref({});
    const finalConvertedAmount = ref(null);
    const finalToCurrency = ref("");
    const showNotification = ref(false);
    const notificationMessage = ref("");
    const notificationType = ref("success");
    const dropdown = ref({ open: '' });

    const closeDropdown = () => {
      dropdown.value.open = '';
    };
    const handleClickOutside = (event) => {
      const dropdownElements = document.querySelectorAll('.custom-select');
      let clickedInside = false;

      dropdownElements.forEach((element) => {
        if (element.contains(event.target)) {
          clickedInside = true;
        }
      });

      if (!clickedInside) {
        closeDropdown();
      }
    };
    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    const orderedCurrencies = [
      "USD", "EUR", "RUB", "BYN", "UAH", "MDL", "UZS", "AED", "ARS", "AUD",
      "BGN", "BRL", "BSD", "CAD", "CHF", "CLP", "CNY", "COP", "CZK", "DKK",
      "DOP", "EGP", "FJD", "GTQ", "HKD", "HRK", "HUF", "IDR", "ILS", "INR",
      "ISK", "JPY", "KRW", "KZT", "MVR", "MXN", "MYR", "NOK", "NZD", "PAB",
      "PEN", "PKR", "PLN", "PYG", "RON", "SAR", "SEK", "SGD", "THB", "TRY",
      "TWD", "UYU", "ZAR"
    ];

    onMounted(async () => {
      try {
        const response = await fetch("https://api.exchangerate-api.com/v4/latest/USD");
        const data = await response.json();
        rates.value = data.rates;
      } catch (error) {
        showNotification.value = true;
        notificationMessage.value = "Ошибка при загрузке курсов валют.";
        notificationType.value = "error";
      }
    });

    const filterDigits = () => {
      amount.value = amount.value.replace(/[^\d.]/g, "");
    };

    const convertCurrency = () => {
      if (!amount.value || !fromCurrency.value || !toCurrency.value) {
        triggerNotification("Пожалуйста, заполните все поля", "warning");
        return;
      }

      const fromRate = rates.value[fromCurrency.value];
      const toRate = rates.value[toCurrency.value];
      finalConvertedAmount.value = ((amount.value / fromRate) * toRate).toFixed(2);
      finalToCurrency.value = toCurrency.value;
    };

    const copyToClipboard = async () => {
      if (finalConvertedAmount.value !== null) {
        try {
          await navigator.clipboard.writeText(finalConvertedAmount.value);
          triggerNotification("Скопировано в буфер обмена!", "success");
        } catch (error) {
          triggerNotification("Не удалось скопировать текст.", "error");
        }
      } else {
        triggerNotification("Нет данных для копирования.", "warning");
      }
    };

    const resetFields = () => {
      amount.value = "";
      fromCurrency.value = "";
      toCurrency.value = "";
      finalConvertedAmount.value = null;
      finalToCurrency.value = "";
    };

    const getCurrencyName = (currency) => {
      const currencyNames = {
        USD: "Доллар США", EUR: "Евро", RUB: "Российский рубль", BYN: "Белорусский рубль", UAH: "Украинская гривна",
        MDL: "Молдавский лей", UZS: "Узбекских сумов", AED: "Дирхам ОАЭ", ARS: "Аргентинское песо", AUD: "Австралийский доллар",
        BGN: "Болгарский лев", BRL: "Бразильский реал", BSD: "Багамский доллар", CAD: "Канадский доллар", CHF: "Швейцарский франк",
        CLP: "Чилийское песо", CNY: "Китайский юань", COP: "Колумбийское песо", CZK: "Чешская крона", DKK: "Датская крона",
        DOP: "Доминиканское песо", EGP: "Египетский фунт", FJD: "Доллар Фиджи", GTQ: "Гватемальский кетсаль", HKD: "Гонконгский доллар",
        HRK: "Хорватская куна", HUF: "Венгерский форинт", IDR: "Индонезийская рупия", ILS: "Новый израильский шекель",
        INR: "Индийская рупия", ISK: "Исландская крона", JPY: "Японская иена", KRW: "Южнокорейская вона", KZT: "Казахстанский тенге",
        MVR: "Мальдивская руфия", MXN: "Мексиканское песо", MYR: "Малайзийский ринггит", NOK: "Норвежская крона",
        NZD: "Новозеландский доллар", PAB: "Панамская Бальбоа", PEN: "Перуанский соль", PKR: "Пакистанская рупия",
        PLN: "Польский злотый", PYG: "Парагвайский гуарани", RON: "Румынский лей", SAR: "Саудовский риял",
        SEK: "Шведская крона", SGD: "Сингапурский доллар", THB: "Таиландский бат", TRY: "Турецкая лира",
        TWD: "Новый тайваньский доллар", UYU: "Уругвайское песо", ZAR: "Южноафриканский рэнд"
      };
      return currencyNames[currency] || currency;
    };

    const toggleDropdown = (type) => {
      dropdown.value.open = dropdown.value.open === type ? '' : type;
    };

    const selectCurrency = (type, currency) => {
      if (type === 'from') {
        fromCurrency.value = currency;
      } else if (type === 'to') {
        toCurrency.value = currency;
      }
      closeDropdown(); // Закрыть dropdown после выбора валюты
    };

    const fromCurrencyName = computed(() => getCurrencyName(fromCurrency.value));
    const toCurrencyName = computed(() => getCurrencyName(toCurrency.value));

    const triggerNotification = (message, type) => {
      notificationMessage.value = message;
      notificationType.value = type;
      showNotification.value = true;
      setTimeout(() => showNotification.value = false, 3000);
    };

    return {
      amount,
      fromCurrency,
      toCurrency,
      finalConvertedAmount,
      finalToCurrency,
      orderedCurrencies,
      filterDigits,
      convertCurrency,
      copyToClipboard,
      resetFields,
      getCurrencyName,
      showNotification,
      notificationMessage,
      notificationType,
      toggleDropdown,
      selectCurrency,
      dropdown,
      fromCurrencyName,
      toCurrencyName
    };
  }
};


</script>
