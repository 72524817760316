<template>
  <div id="app">
    <div class="main_content animate__animated animate__fadeIn">
      <div class="main-container">
        <main>
          <div class="content-flex">
            <h1
              class="heading text-center h1_desp animate__animated animate__backInDown"
            >
              Онлайн-конвертер валют
            </h1>

            <div class="container">
              <div class="main">
                <convent></convent>
              </div>
            </div>
          </div>
          <CurrencyRatesTable />
        </main>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import convent from "./components/convent.vue";
import CurrencyRatesTable from "./components/CurrencyRatesTable.vue";

export default {
  name: "App",
  components: { Footer, convent, CurrencyRatesTable },
};
</script>